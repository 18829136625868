import { css as d } from "@emotion/react";
var s;
(function(e) {
  e[e.MobileSmall = 319] = "MobileSmall", e[e.MobileDefault = 374] = "MobileDefault", e[e.DesktopSmall = 767] = "DesktopSmall", e[e.DesktopMedium = 1169] = "DesktopMedium", e[e.DesktopDefault = 1279] = "DesktopDefault", e[e.DesktopLarge = 1439] = "DesktopLarge", e[e.GridLarge = 1169] = "GridLarge", e[e.GridMedium = 999] = "GridMedium", e[e.GridSmall = 599] = "GridSmall";
})(s || (s = {}));
const a = (e, o) => /* @__PURE__ */ d("@media (max-width:", e, "px){", o, ";}"), i = (e, o, l) => /* @__PURE__ */ d("@media (min-width:", e + 1, "px) and (max-width:", o, "px){", l, ";}"), p = (e, o) => /* @__PURE__ */ d("@media (min-width:", e + 1, "px){", o, ";}"), t = (e) => /* @__PURE__ */ d("@media (hover:none),(pointer:coarse),(hover:none) and (pointer:coarse),(hover:none) and (pointer:fine){", e, ";}"), h = (e) => /* @__PURE__ */ d("@media (hover:hover) and (pointer:fine){&:hover{", e, ";}}");
export {
  s as B,
  i as b,
  p as g,
  h,
  a as o,
  t
};
