import { css as l } from "@emotion/react";
import { t as o } from "./chunks/chunk-565b8be9.prod.js";
import { B as h, b as L, g as w, h as u, o as T } from "./chunks/chunk-565b8be9.prod.js";
const r = {
  colorScheme: "light",
  colors: {
    grey600: "#141414",
    grey550: "#555555",
    grey500: "#a5a5a5",
    grey400: "#cccccc",
    grey300: "#e6e6e6",
    grey200: "#f0f0f0",
    grey100: "#f5f5f5",
    grey080: "#fafafa",
    white: "#ffffff",
    black: "#000000",
    blue: "#1E9EFF",
    red: "#DC3232",
    orange: "#ff6011",
    mint: "#0cd8a6",
    darkNavy: "#283241",
    gold: "#c8b464",
    purple: "#8e58ff",
    babyBlue: "#ebf8ff",
    paymentBlue: "#f1faff",
    bgBase: "#ffffff",
    bgTabBar: "#ffffff",
    bgElevated: "#ffffff",
    fillPrimary: "#141414",
    fillSecondary: "#787878",
    fillTertiary: "#787878",
    fillCarouselNum: "rgba(20, 20, 20, 0.2)",
    fillCta: "#141414",
    fillSelectText: "#aaa068",
    fillSetBand: "rgba(240, 240, 240, 0.9)",
    fillTopBox: "#373737",
    fillSkeletonElevated: "#fafafa",
    dimDialog: "rgba(0, 0, 0, 0.7)",
    dimThumb: "rgba(0, 0, 0, 0.04)"
  }
}, c = {
  colorScheme: "dark",
  colors: {
    grey600: "#999999",
    grey500: "#666666",
    grey400: "#505050",
    grey300: "#373737",
    grey200: "#2d2d2d",
    grey100: "#242424",
    grey080: "#1e1e1e",
    grey060: "#1a1a1a",
    grey030: "#0e0e0e",
    white: "#ffffff",
    black: "#000000",
    blue: "#1496E6",
    red: "#8c1e14",
    orange: "#eb5a11",
    mint: "#00c8aa",
    darkNavy: "#313a49",
    gold: "#b4a050",
    purple: "#7847de",
    babyBlue: "#28404d",
    paymentBlue: "#f1faff",
    bgBase: "#1a1a1a",
    bgTabBar: "#0e0e0e",
    bgElevated: "#242424",
    fillPrimary: "#ffffff",
    fillSecondary: "#666666",
    fillTertiary: "#999999",
    fillCarouselNum: "rgba(102, 102, 102, 0.2)",
    fillCta: "#f5f5f5",
    fillSelectText: "#aaa068",
    fillSetBand: "rgba(240, 240, 240, 0.9)",
    fillTopBox: "#373737",
    fillSkeletonElevated: "#2d2d2d",
    dimDialog: "rgba(0, 0, 0, 0.7)",
    dimThumb: "rgba(0, 0, 0, 0.04)"
  }
}, E = r, b = /* @__PURE__ */ l("position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;border:0;clip:rect(0,0,0,0);"), g = (e, a) => /* @__PURE__ */ l("display:block;display:-webkit-box;-webkit-line-clamp:", e, ";-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;word-break:keep-all;word-wrap:break-word;white-space:normal;max-height:calc(", a, " * ", e, ");line-height:", a, ";");
var f;
(function(e) {
  e[e.LEVEL_TOASTMESSAGE = 1e4] = "LEVEL_TOASTMESSAGE", e[e.LEVEL_MODAL = 9e3] = "LEVEL_MODAL", e[e.LEVEL_OVERLAY = 8e3] = "LEVEL_OVERLAY", e[e.LEVEL_HEADER = 7e3] = "LEVEL_HEADER", e[e.LEVEL_TOOLTIP = 6e3] = "LEVEL_TOOLTIP", e[e.LEVEL_DROPDOWN = 5e3] = "LEVEL_DROPDOWN", e[e.LEVEL_BASE = 0] = "LEVEL_BASE", e[e.LEVEL_BELOW = -1] = "LEVEL_BELOW";
})(f || (f = {}));
const n = o(/* @__PURE__ */ l("display:none;")), s = /* @__PURE__ */ l("overflow:-moz-scrollbars-none;-ms-overflow-style:none;&::-webkit-scrollbar{-webkit-appearance:none;width:0;height:0;display:none !important;}&{overflow:-moz-scrollbars-none;}-webkit-overflow-scrolling:touch;scrollbar-width:none;"), d = /* @__PURE__ */ l(":focus{outline:none;}:active{outline:none;}");
export {
  h as BreakPoint,
  f as ZIndexLayer,
  b as a11y,
  L as between,
  d as clearOutline,
  c as darkTheme,
  E as defaultTheme,
  n as displayNoneForTouchDevice,
  w as greaterThan,
  u as hover,
  r as lightTheme,
  g as lineClamp,
  T as orBelow,
  s as scrollBarHidden,
  o as touchDevice
};
